import React, { PureComponent } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { withSizes } from 'react-sizes';
import { withTheme } from 'styled-components';

import { getLogoSelector } from 'common/selectors';
import { getPageDataSelector } from 'common/selectors/page';
import {
  isLayoutNavbarEnabled,
  reactiveTemplateConfigSelector
} from 'common/selectors/theme';

import { fetchStaticBlocks } from 'common/actions/PageActions';

import { getMapSizesToPropsCustom, setToObject } from 'common/utils';

import loadable from '@loadable/component';
import { Block } from 'common/components/styleguide/grid';

const InfoBox = loadable(() => import('common/blocks/infobox'));
const HeaderVariant = loadable(props =>
  import(`common/blocks/header/${props.blockVariant}`)
);
const Navigation = loadable(() => import('common/blocks/navigation'));

const HeaderWrapper = ({ isFixed = false, children }) => {
  let customStyle = '';
  if (isFixed) {
    customStyle = `
      position: fixed;
      z-index: 999;
      top: 0;
      left: 0;
      right: 0;
    `;
  }
  return (
    <Block backgroundColor={'transparent'} marginConfig={{}} paddingConfig={{}} customStyle={customStyle}>
      {children}
    </Block>
  );
};

class Header extends PureComponent {
  state = {
    navIsHidden: true,
    searchIsHidden: true
  };
  toggleNav = () => {
    const { navIsHidden } = this.state;
    const newNavIsHidden = !navIsHidden;
    this.setState({ navIsHidden: newNavIsHidden }, () => {
      if (!newNavIsHidden) {
        document.querySelector('body').classList.add('no-scroll');
      } else {
        document.querySelector('body').classList.remove('no-scroll');
      }
    });
  };
  toggleSearch = () => {
    const { searchIsHidden } = this.state;
    this.setState({ searchIsHidden: !searchIsHidden });
  };
  componentDidMount() {
    const { dispatchFetchStaticBlocks } = this.props;
    dispatchFetchStaticBlocks();
  }
  render() {
    const {
      isNavbarEnabled,
      logo,
      theme,
      blockSettings,
      isMD,
      isFixed = false,
      closeableInfoBox = false,
      ...props
    } = this.props;
    const { navIsHidden, searchIsHidden } = this.state;

    return (
      <HeaderWrapper isFixed={isFixed}>
        <InfoBox showCloseButton={closeableInfoBox} />
        <HeaderVariant
          blockVariant={_.get(blockSettings, 'blockVariant')}
          toggleNav={this.toggleNav}
          toggleSearch={this.toggleSearch}
          logo={logo}
          theme={theme}
          isNavbarEnabled={isNavbarEnabled}
          searchIsHidden={searchIsHidden}
          isMD={isMD}
          isFixed={isFixed}
          navIsHidden={navIsHidden}
          {...props}
        >
          {isNavbarEnabled ? (
            <Navigation
              toggleNav={this.toggleNav}
              toggleSearch={this.toggleSearch}
              navIsHidden={navIsHidden}
            />
          ) : null}
        </HeaderVariant>
      </HeaderWrapper>
    );
  }
}

let blockSettings = {};
const makeMapStateToProps = () => {
  const getLogo = getLogoSelector();
  const getPageData = getPageDataSelector();
  const isNavbarEnabled = isLayoutNavbarEnabled();
  const reactiveTemplateConfig = reactiveTemplateConfigSelector(
    'HeaderVariant'
  );
  return (state, props) => {
    return {
      logo: getLogo(state, props),
      isNavbarEnabled: isNavbarEnabled(state, props),
      staticBlocks: _.get(getPageData(state, props), 'staticBlocks'),
      blockSettings: setToObject(blockSettings, {
        blockVariant: reactiveTemplateConfig(state, props)
      })
    };
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatchFetchStaticBlocks: () => {
      dispatch(fetchStaticBlocks());
    }
  };
};

const HeaderConnected = connect(
  makeMapStateToProps,
  mapDispatchToProps
)(Header);

export default withTheme(
  withSizes(getMapSizesToPropsCustom())(HeaderConnected)
);
