import { createSelector } from 'reselect';
import { get, isNumber } from 'lodash';

const getTheme = state => {
  const { app = {} } = state;
  return app;
};

export const isLayoutTopBarEnabled = () =>
  createSelector([getTheme], theme => {
    return parseInt(
      get(theme, 'webInstance.globalSettings.isLayoutTopBarEnabled', 1),
      10
    )
      ? true
      : false;
  });

export const isLayoutNavbarEnabled = () =>
  createSelector([getTheme], theme => {
    return parseInt(
      get(theme, 'webInstance.globalSettings.isLayoutNavbarEnabled', 1),
      10
    )
      ? true
      : false;
  });

export const isLayoutFooterEnabled = () =>
  createSelector([getTheme], theme => {
    return parseInt(
      get(theme, 'webInstance.globalSettings.isLayoutFooterEnabled', 1),
      10
    )
      ? true
      : false;
  });

export const isLayoutCopyright = () =>
  createSelector([getTheme], theme => {
    return parseInt(
      get(theme, 'webInstance.globalSettings.isLayoutCopyrightEnabled', 1),
      10
    )
      ? true
      : false;
  });

export const getLayoutTopbarSettings = () =>
  createSelector([getTheme], theme => {
    const settings = {
      isMenuEnabled: parseInt(
        get(theme, 'webInstance.globalSettings.isLayoutTopbarMenuEnabled', 1),
        10
      )
        ? true
        : false,
      isPhoneEnabled: parseInt(
        get(theme, 'webInstance.globalSettings.isLayoutTopbarPhoneEnabled', 1),
        10
      )
        ? true
        : false,
      isEmailEnabled: parseInt(
        get(theme, 'webInstance.globalSettings.isLayoutTopbarEmailEnabled', 1),
        10
      )
        ? true
        : false,
      isFacebookEnabled: parseInt(
        get(
          theme,
          'webInstance.globalSettings.isLayoutTopbarFacebookEnabled',
          1
        ),
        10
      )
        ? true
        : false,
      isInstagramEnabled: parseInt(
        get(
          theme,
          'webInstance.globalSettings.isLayoutTopbarInstagramEnabled',
          1
        ),
        10
      )
        ? true
        : false,
      isCurrencyEnabled: parseInt(
        get(
          theme,
          'webInstance.globalSettings.isLayoutTopbarCurrencyEnabled',
          0
        ),
        10
      )
        ? true
        : false,
      isLanguageEnabled: parseInt(
        get(
          theme,
          'webInstance.globalSettings.isLayoutTopbarLanguageEnabled',
          0
        ),
        10
      )
        ? true
        : false
    };
    return settings;
  });

export const getLayoutHomepageSettings = () =>
  createSelector([getTheme], theme => {
    const settings = {
      isBannersEnabled: parseInt(
        get(
          theme,
          'webInstance.globalSettings.isLayoutHomepageBannersEnabled',
          null
        ),
        10
      )
        ? true
        : false,
      isProductsNewsEnabled: parseInt(
        get(
          theme,
          'webInstance.globalSettings.isLayoutHomepageProductsNewsEnabled',
          null
        ),
        10
      )
        ? true
        : false,
      isProductsFavouriteEnabled: parseInt(
        get(
          theme,
          'webInstance.globalSettings.isLayoutHomepageProductsFavouriteEnabled',
          null
        ),
        10
      )
        ? true
        : false,
      isProductsLatestEnabled: parseInt(
        get(
          theme,
          'webInstance.globalSettings.isLayoutHomepageProductsLatestEnabled',
          null
        ),
        10
      )
        ? true
        : false,
      isProductsLastSeenEnabled: parseInt(
        get(
          theme,
          'webInstance.globalSettings.isLayoutHomepageProductsLastSeenEnabled',
          null
        ),
        10
      )
        ? true
        : false,
      isNewsletterEnabled: parseInt(
        get(
          theme,
          'webInstance.globalSettings.isLayoutHomepageNewsletterEnabled',
          null
        ),
        10
      )
        ? true
        : false,
      isBlogsEnabled: parseInt(
          get(
            theme,
            'webInstance.globalSettings.isLayoutHomepageBlogsEnabled',
            null
          ),
          10
        )
          ? true
          : false,
    };
    return settings;
  });

export const getLayoutCategorySettings = () =>
  createSelector([getTheme], theme => {
    const settings = {
      isBannersEnabled: parseInt(
        get(
          theme,
          'webInstance.globalSettings.isLayoutCategoryBannersEnabled',
          1
        ),
        10
      )
        ? true
        : false,
      isLayoutSwitcherEnabled: parseInt(
        get(
          theme,
          'webInstance.globalSettings.isLayoutCategoryLayoutSwitcherEnabled',
          1
        ),
        10
      )
        ? true
        : false,
      isOrderingEnabled: parseInt(
        get(
          theme,
          'webInstance.globalSettings.isLayoutCategoryOrderingEnabled',
          1
        ),
        10
      )
        ? true
        : false,
      isPerPageEnabled: parseInt(
        get(
          theme,
          'webInstance.globalSettings.isLayoutCategoryPerPageEnabled',
          1
        ),
        10
      )
        ? true
        : false
    };
    return settings;
  });

export const isRatingEnabledSelector = () =>
  createSelector([getTheme], theme => {
    return false;
  });

export const isWishListEnabledSelector = () =>
  createSelector([getTheme], theme => {
    return parseInt(
      get(theme, 'webInstance.globalSettings.isWishListEnabled', 1),
      10
    )
      ? true
      : false;
  });

export const isCompareEnabledSelector = () =>
  createSelector([getTheme], theme => {
    return parseInt(
      get(theme, 'webInstance.globalSettings.isCompareEnabled', 1),
      10
    )
      ? true
      : false;
  });

export const isBrandEnabledSelector = () =>
  createSelector([getTheme], theme => {
    return parseInt(
      get(theme, 'webInstance.globalSettings.isBrandEnabled', 1),
      10
    )
      ? true
      : false;
  });

export const isStoreSelectEnabledSelector = () =>
  createSelector([getTheme], theme => {
    return parseInt(
      get(theme, 'webInstance.globalSettings.isStoreSelectEnabled', 1),
      10
    )
      ? true
      : false;
  });

//@TODO zmazat
export const isAddToCartEnabledSelector = () =>
  createSelector([getTheme], theme => {
    return parseInt(
      get(theme, 'webInstance.globalSettings.themeIsCompareEnabled', null),
      10
    )
      ? true
      : true;
  });

export const isProductCardAddToCartEnabledSelector = () =>
  createSelector([getTheme], theme => {
    return parseInt(
      get(theme, 'webInstance.globalSettings.themeIsCompareEnabled', 0),
      10
    )
      ? true
      : true;
  });

export const isProductCardBrandEnabledSelector = () =>
  createSelector([getTheme], theme => {
    return parseInt(
      get(theme, 'webInstance.globalSettings.themeIsBrandEnabled', 0),
      10
    )
      ? true
      : true;
  });

export const isPriceFilterEnabledSelector = () =>
  createSelector([getTheme], theme => {
    return parseInt(
      get(theme, 'webInstance.globalSettings.themeIsPriceFilterEnabled', 0),
      10
    )
      ? true
      : true;
  });

export const isMeasureUnitEnabledSelector = () =>
  createSelector([getTheme], theme => {
    return parseInt(
      get(theme, 'webInstance.globalSettings.themeIsMeasureUnitEnabled', 0),
      10
    )
      ? true
      : true;
  });

export const isStrictTownsEnabledSelector = () =>
  createSelector([getTheme], theme => {
    return parseInt(
      get(theme, 'webInstance.globalSettings.themeIsStrictTownsEnabled', 0),
      10
    )
      ? true
      : false;
  });

export const reactiveTemplateConfigSelector = (
  variantType,
  generateName = true,
  variantName = ''
) => {
  return createSelector([getTheme], theme => {
    const positionConfig = {
      TopBarVariant: 0,
      HeaderVariant: 1,
      NavigationVariant: 2,
      FooterVariant: 3,
      CopyrightVariant: 4,
      ProfileAccountVariant: 5, //todo
      ProfileOrderVariant: 6, //todo
      ProfileOrdersVariant: 7, //todo
      ProfileSidebarVariant: 8, //todo
      BannerHomepageMainVariant: 9,
      BannerHomepageSecondaryVariant: 10,
      BannerHomepageThirdVariant: 11,
      BannerCategoryMainVariant: 12,
      BannerProductMainVariant: 13,
      BannerFooterMainVariant: 14,
      BannerArticleMainVariant: 15,
      BlogCardElementVariant: 16,
      BlogDetailVariant: 17,
      BlogListingVariant: 18,
      CategoryHeaderVariant: 19, //todos
      AutocompleteVariant: 20,
      AutocompleteResultsVariant: 21,
      CartButtonVariant: 22, //todo
      CompareButtonVariant: 23, //todo
      FunctionalCookieConsentVariant: 24, //todo
      FunctionalLanguageSelectorVariant: 25, //todo
      NewsletterVariant: 26,
      UserButtonVariant: 27,
      WishlistButtonVariant: 28, //todo
      FunctionalCompareButtonVariant: 29, //todo
      ProductCardVariant: 30,
      BlogElementVariant: 31,
      GalleryVariant: 32,
      ProductSummaryVariant: 33,
      ProductFormVariant: 34,
      ProductPriceVariant: 35,
      AddToWishlistButtonVariant: 36,
      AddToCompareButtonVariant: 37,
      TestimonialElementVariant: 38,
      TestimonialCardVariant: 39,
      ContactElementVariant: 40,
      ContactFormVariant: 41,
      NewProductsVariant: 42,
      FavouriteProductsVariant: 43,
      BlogCardListingVariant: 44,
      ProductStatusVariant: 45,
      AddToCartButtonVariant: 46,
      StoresButtonVariant: 47,
      SearchButtonVariant: 48,
      StoresVariant: 49,
      StoreCardVariant: 50,
      StoreVariant: 51,
      InfoBoxVariant: 52,
      RequestPriceFormVariant: 53,
      RegisterFormVariant: 54,
      CheckoutContactFormVariant: 55,
      ProfileOrdersVariant: 56,
      ProfileInvoicesVariant: 57,
      ProfileDeliveryNotesVariant: 58,
      AgeVerificationModalVariant: 59
    };

    const reactiveDefaultBlockVariant = 1;

    const getPositionValue = (config, position) =>
      config.charAt(position) ? config.charAt(position) : false;

    const getName = (variantName, variantType, generateName, value) =>
      variantName + (generateName ? variantType + value : value);

    const reactiveBlockVariantPosition = get(positionConfig, variantType);
    const reactiveConfigOverride = get(theme, 'config.reactiveTemplateConfig');
    const reactiveConfigTheme = get(
      theme,
      'webInstance.globalSettings.reactive_blocks_configuration'
    );

    if (!isNumber(reactiveBlockVariantPosition)) {
      return getName(
        variantName,
        variantType,
        generateName,
        reactiveDefaultBlockVariant
      );
    }

    if (
      reactiveConfigTheme &&
      getPositionValue(reactiveConfigTheme, reactiveBlockVariantPosition)
    ) {
      return getName(
        variantName,
        variantType,
        generateName,
        getPositionValue(reactiveConfigTheme, reactiveBlockVariantPosition)
      );
    }

    if (
      reactiveConfigOverride &&
      getPositionValue(reactiveConfigOverride, reactiveBlockVariantPosition)
    ) {
      return getName(
        variantName,
        variantType,
        generateName,
        getPositionValue(reactiveConfigOverride, reactiveBlockVariantPosition)
      );
    }

    return getName(
      variantName,
      variantType,
      generateName,
      reactiveDefaultBlockVariant
    );
  });
};
